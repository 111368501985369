import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 35rem;
  flex-flow: row;
  min-height: 100%;
  font-family: Helvetica;
  background-image: url("wave.svg");
  background-position: left bottom; /* or 0&#37; 100% */
  background-repeat: no-repeat;
  color: black;
`

const FeatureSection = styled.div`
  display: flex;
  background: #3258a9;
  color: white;
  padding: 0 2% 0 2%;
  flex-flow: wrap;
  flex: 1;
  font-family: Helvetica;
`

const Feature = styled.div`
  width: 40%;
  flex-direction: column;
  padding: 2% 5% 0 5%;
`
const Start = styled.div`
  font-size: 1.5rem;
  backgroundcolor: blue;
  margin-bottom: 5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  a:hover: white;
  background-color: blue;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 20%;
  }
`

const HeaderText = styled.div`
  flex: 1,
  margin: auto;
  width: 100%;
  border: 0px solid green;
  padding-top: 1.5rem;
  padding-bottom: 5rem;
  text-align: center;
  font-family: montserrat;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProductSectionRow = styled.div`
  display: flex;
  flex: 1;
  width: 80%;
  padding: 1rem;
  text-align: center;
  overflow: visible !important;
  @media (max-width: 768px) {
    flex-flow: column;
  }
  @media (min-width: 768px) {
    flex-flow: row;
  }
`
const ProductSection = styled.div`
  flex: 1;
  padding-left: 4rem;
  padding-right: 4rem;
`
const DesktopNavbar = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex: 1;
    width: 100%;
    flex-flow: row;
    height: auto;
  }
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row;
  height: auto;
`

const LetImage = styled(Img)`
  overflow: hidden;
  max-width: 200px;
  margin: 0 auto;
`

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      shopifyImage: file(relativePath: { eq: "shopify.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <HeaderSection>
        <HeaderText>
          <div
            style={{
              fontSize: "3.5rem",
              padding: "2rem"
            }}
          >
            create virtual classrooms.
          </div>
          <h4>offer online courses, ebooks and live classes to your students anytime anywhere.</h4>

          <Start>
            <Link
              to="/select"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Get started!
            </Link>
          </Start>
        </HeaderText>
      </HeaderSection>

      <FeatureSection>
        <h1
          style={{
            display: "flex",
            alignContent: "center",
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "-2rem",
          }}
        >
          Features
        </h1>

        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/people.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Student management</h4>

              <p>
                Manage your students and give individual and group access to
                courses and other resources.View progress reports of individual
                students.
              </p>
            </div>
          </div>
        </Feature>
        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/thunder.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Live sessions</h4>

              <p>
                Schedule and Hold live classes with your students . Send
                automated reminders on classess .
              </p>
            </div>
          </div>
        </Feature>
        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/question.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Examination and assesments</h4>

              <p>
                Perform assessment of your students with quizzess and exams .
                Get automated and manual grading of your students.
              </p>
            </div>
          </div>
        </Feature>
        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/phone.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Course publisher </h4>

              <p>
                A easy to use course creator that enables you create enganging
                content for your students. Create courses and live classes with
                rich mutlimedia content such as videos , audio , text, files and
                quizzes
              </p>
            </div>
          </div>
        </Feature>

        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/support.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Support</h4>

              <p>
                We have 24/7 customer support available to offer support to you
                incase any issue arises.
              </p>
            </div>
          </div>
        </Feature>

        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/layout.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Layouts and templates</h4>

              <p>
                We offer several templates that will allow you start creating
                your courses in as little time as possible. Our layouting
                technology allows you to customize every template to suit your
                needs and usecase.
              </p>
            </div>
          </div>
        </Feature>
        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              <img src="/shopping_cart.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>eCommerce</h4>

              <p>
                Sell your live classes and pre recorded courses on your
                ecommerce platforms. We have native integration with top popular
                ecommerce platforms.
              </p>
            </div>
          </div>
        </Feature>
        <Feature>
          <div style={{ display: "flex", flex: 1, flexFlow: "row" }}>
            <div style={{ flex: 0.2, padding: "3rem 0 0 0 " }}>
              {" "}
              <img src="/customization.svg" width="50%" />
            </div>
            <div style={{ flex: 0.8 }}>
              <h4>Customization</h4>

              <p>
                We offer an open API and customization .This enables inflowkit
                to integrate with your existing systems and platforms seamlessly.
              </p>
            </div>
          </div>
        </Feature>
      </FeatureSection>

      <div style={{ backgroundColor: "white", marginTop: -10 }}>
        <h1
          style={{
            fontFamily: "Montserrat",
            fontSize: "2em",
            fontWeight: 200,
            color: "#18213d",
            textAlign: "center",
          }}
        >
          Cross-Platform support
        </h1>

        <DesktopNavbar>
          <ProductSectionRow>
            <ProductSection>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    alert(
                      "We are working on releasing the android app, send us an email to alert you when it will be ready"
                    )
                  }}
                >
                  <img src="/google-play-badge.png" width="40%" />
                </a>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 200,
                    color: "black",
                  }}
                >
                  Coming Soon!
                </p>
              </div>
            </ProductSection>
            <ProductSection>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    alert(
                      "Please send us an email at contact@inflowkit.com to get the windows app"
                    )
                  }}
                >
                  <img src="/ms_store.png" width="40%" />
                </a>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 200,
                    color: "black",
                  }}
                >
                  Coming Soon on windows!
                </p>
              </div>
            </ProductSection>
            <ProductSection>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    alert(
                      "Please send us an email at contact@inflowkit.com to get the windows app"
                    )
                  }}
                >
                  <img src="/ubuntu.png" width="40%" />
                </a>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 200,
                    color: "black",
                  }}
                >
                  Coming Soon on ubuntu!
                </p>
              </div>
            </ProductSection>
          </ProductSectionRow>
        </DesktopNavbar>
      </div>
    </Layout>
  )
}
export default IndexPage
